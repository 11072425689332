<script setup lang="ts">
const { locale } = useI18n()
const localePath = useLocalePath()
const colorMode = useColorMode()
const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  },
})

const { navLinks } = useNavLinks()

const localizedLogoUrl = computed(() => {
  return locale.value === 'hk'
    ? '/img/clinic-logo/shoebill-co-traveller-long-hk.svg'
    : '/img/clinic-logo/shoebill-co-traveller-long-en.svg'
})

const modeIcon = computed(() => {
  return isDark.value ? 'i-tabler-sun' : 'i-tabler-moon'
})
</script>

<template>
  <UHeader>
    <template #left>
      <NuxtLink :to="localePath('/')" aria-label="Logo" class="">
        <img
          :src="localizedLogoUrl"
          :alt="$t('app.title')"
          class="w-auto h-10 highlight-none"
        />
      </NuxtLink>
    </template>

    <template #center>
      <KHeaderLinks :links="navLinks ?? []" ariaCurrentValue="page" />
    </template>

    <template #right>
      <ClientOnly>
        <UButton
          :icon="modeIcon"
          variant="ghost"
          color="gray"
          aria-label="Toggle Color Mode"
          @click="isDark = !isDark"
        />
        <template #fallback>
          <div class="w-8 h-8" ></div>
        </template>
      </ClientOnly>

      <LangSwitch />
    </template>

    <template #panel>
      <KNavigationTree
        :links="navLinks ?? []"
        ariaCurrentValue="page"
        :multiple="false"
        defaultOpen
      />
    </template>
  </UHeader>
</template>
