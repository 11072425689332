import type { Nav } from '~/types/Nav'

export const transformNavLinks = (
  links: Nav[],
  localizeString: (en: string, hk: string) => string,
  localePath: (path: string) => string
) => {
  return links
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .map((link) => ({
      label: localizeString(link.label, link.hkLabel),
      description: localizeString(link.description, link.hkDescription),
      icon: link.icon,
      to: localePath(link.to),
      target: link.target || null,
      cover: link.cover.formats?.large?.url,
      alt: link.cover.alternativeText,
      children:
        link.children?.map((child) => ({
          id: child.id,
          label: localizeString(child.label, child.hkLabel),
          title: localizeString(child.label, child.hkLabel),
          description: localizeString(child.description, child.hkDescription),
          icon: child.icon,
          to: localePath(child.to),
          target: child.target || null,
          cover: child.cover.formats?.large?.url,
          alt: child.cover.alternativeText,
          isLandingVisible: child.isLandingVisible,
        })) || [],
    }))
}
