import { API_ANNOUNCEMENT } from '~/utils/constants'

interface Announcement {
  title: string
  description: string
  content: string
  cover: string
  alt: string | null
  publishedAt: string
  isActive: boolean
}

export const useAnnouncement = () => {
  const { localizeString } = useLocalization()
  const { locale } = useI18n()

  const {
    data: announcement,
    error,
    status,
  } = useLazyFetch(API_ANNOUNCEMENT, {
    key: `announcement-${locale.value}`,
    transform: (response): Announcement => ({
      title: localizeString(response.title, response.hkTitle),
      description: localizeString(response.description, response.hkDescription),
      content: localizeString(response.content, response.hkContent),
      cover: response.ogImg.formats.large.url,
      alt: response.ogImg.alternativeText,
      publishedAt: response.publishedAt,
      isActive: response.isActive,
    }),
    watch: [locale],
  })

  return {
    announcement,
    error,
    status,
  }
}
