export const API_ANNOUNCEMENT = '/api/announcement';
export const API_ANNOUNCEMENT_PAGE = '/api/announcement-page';
export const API_APPOINTMENT = '/api/appointment';
export const API_APPOINTMENT_SERVICE = '/api/appointment/service';
export const API_APPOINTMENT_SERVICES = '/api/appointment-services';
export const API_APPOINTMENT_VACCINE = '/api/appointment/vaccine';
export const API_ARTICLE = '/api/article';
export const API_ARTICLES = '/api/articles';
export const API_ARTICLE_BY_SLUG = '/api/article/{slug}';
export const API_ARTICLE_CATEGORIES = '/api/article-categories';
export const API_ARTICLE_CATEGORY = '/api/article/category';
export const API_CLINIC = '/api/clinic';
export const API_HEALTH_SCREENING_CATEGORIES = '/api/health-screening-categories';
export const API_HEALTH_SCREENING_PACKAGES = '/api/health-screening-packages';
export const API_LINKS = '/api/links';
export const API_MAINTENANCE = '/api/maintenance';
export const API_NAV = '/api/nav';
export const API_OPENING = '/api/opening';
export const API_OPENINGS = '/api/openings';
export const API_PACKAGE = '/api/package';
export const API_SERVICE_CATEGORIES = '/api/service-categories'
export const API_SERVICE_PAGE_BY_SLUG = '/api/service-page/{slug}';
export const API_VACCINE = '/api/vaccine';
export const API_VACCINES = '/api/vaccines';
export const API_VACCINE_CATEGORIES = '/api/vaccine-categories';
export const PLATO_API_APPOINTMENT = 'https://clinic.platomedical.com/api/shoebill/appointment';
export const PLATO_API_REPORTS_STOCK_COUNT = 'https://clinic.platomedical.com/api/shoebill/reports/stock_count';
