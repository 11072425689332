<script setup lang="ts">
import type { PropType } from 'vue'
import { getULinkProps } from '#ui/utils'
import type { DeepPartial } from '#ui/types'
import type { FooterLink } from '#ui-pro/types'

const { t } = useI18n()

const appConfig = useAppConfig()

const config = computed(() => ({
  wrapper:
    'flex flex-col md:flex-row items-center justify-center gap-4 lg:gap-6',
  base: 'text-sm',
  active: 'text-gray-900 dark:text-white font-medium',
  inactive:
    'text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300',
  externalIcon: {
    name: appConfig.ui.icons.external,
    base: 'w-3 h-3 absolute top-0.5 -right-3.5 text-gray-400 dark:text-gray-500',
  },
}))

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  links: {
    type: Array as PropType<FooterLink[]>,
    default: () => [],
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<DeepPartial<typeof config.value>>,
    default: () => ({}),
  },
})

const { ui, attrs } = useUI(
  'footer.links',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
)
</script>

<template>
  <ul v-if="links?.length" :class="ui.wrapper" v-bind="attrs">
    <li v-for="(link, index) of links" :key="index" class="relative">
      <ULink
        v-bind="getULinkProps(link)"
        :class="ui.base"
        :activeClass="ui.active"
        :inactiveClass="ui.inactive"
        @click="link.click"
      >
        <template
          v-if="typeof link.to === 'string' && link.to.includes('appointment')"
        >
          <InspiraShimmerButton
            class="shadow-2xl mx-auto px-4 py-2"
            shimmerSize="2px"
            background="rgba(249,115,22,1)"
          >
            {{ t('cta.booking') }}
          </InspiraShimmerButton>
        </template>
        <template v-else>
          {{ link.label }}
          <UIcon
            v-if="link.target === '_blank'"
            :name="ui.externalIcon.name"
            :class="ui.externalIcon.base"
          />
        </template>
      </ULink>
    </li>
  </ul>
</template>
