<script setup lang="ts">
const { t } = useI18n()
const error = useError()
const localePath = useLocalePath()
const { localizeString } = useLocalization()

// Add error descriptions for 500 errors
const get500ErrorDescription = (statusCode: number, message?: string) => {
  if (statusCode !== 500) return message

  const descriptions = {
    default: localizeString(
      'Our servers are experiencing issues. Our team has been notified and is working on a fix.',
      '伺服器出現問題。我們的團隊已收到通知，正在修復中。'
    ),
    database: localizeString(
      "We're having trouble connecting to our database. Please try again in a few minutes.",
      '我們連接資料庫時遇到問題。請稍後再試。'
    ),
    timeout: localizeString(
      'The request timed out. Please check your connection and try again.',
      '請求超時。請檢查您的網路連接並重試。'
    ),
    maintenance: localizeString(
      "We're currently performing maintenance. Please try again shortly.",
      '系統正在維護中。請稍後再試。'
    ),
  }

  if (message?.toLowerCase().includes('database')) return descriptions.database
  if (message?.toLowerCase().includes('timeout')) return descriptions.timeout
  if (message?.toLowerCase().includes('maintenance'))
    return descriptions.maintenance

  return descriptions.default
}

// Add error descriptions for 404 and other errors
const getErrorDescription = (statusCode?: number, message?: string) => {
  const descriptions = {
    404: localizeString(
      "Sorry, we can't find that page. You'll find lots to explore on the home page.",
      '對不起，我們找不到該頁面。您可以在主頁上探索很多內容。'
    ),
    default: localizeString(
      'An unexpected error occurred. Please try again later.',
      '發生意外錯誤。請稍後再試。'
    ),
    timeout: localizeString(
      'The request timed out. Please check your connection and try again.',
      '請求超時。請檢查您的網路連接並重試。'
    ),
    forbidden: localizeString(
      "You don't have permission to access this resource.",
      '您沒有權限訪問此資源。'
    ),
    unauthorized: localizeString(
      'Please log in to access this page.',
      '請登入以訪問此頁面。'
    ),
  }

  if (statusCode === 404) return descriptions[404]
  if (message?.toLowerCase().includes('timeout')) return descriptions.timeout
  if (statusCode === 403) return descriptions.forbidden
  if (statusCode === 401) return descriptions.unauthorized

  return descriptions.default
}

// Get SEO title based on error code
const getSeoTitle = (statusCode?: number) => {
  if (statusCode === 500) {
    return localizeString('500 - Server Error', '500 - 伺服器錯誤')
  }
  if (statusCode === 404) {
    return localizeString('404 - Page Not Found', '404 - 頁面未找到')
  }
  return localizeString(
    `${statusCode || 404} - ErrorName`,
    `${statusCode || 404} - 錯誤`
  )
}

// Use SEO meta tags
useSeoMeta({
  title: getSeoTitle(error.value?.statusCode),
  description:
    error.value?.statusCode === 500
      ? get500ErrorDescription(error.value?.statusCode, error.value?.message)
      : getErrorDescription(error.value?.statusCode, error.value?.message),
  robots: 'noindex, nofollow', // Prevent error pages from being indexed
})
</script>

<template>
  <div class="min-h-screen flex items-center justify-center">
    <!-- 500 Error -->
    <div v-if="error?.statusCode === 500" class="text-center px-4">
      <p class="text-9xl font-bold text-primary-500 mb-4 animate-pulse">500</p>
      <h1 class="text-4xl font-bold text-neutral-900 dark:text-white mb-4">
        Server Error
      </h1>
      <p class="text-gray-700 dark:text-gray-300 text-lg mb-4">
        {{ error.message || 'Internal Server Error' }}
      </p>
      <p class="text-gray-600 dark:text-gray-400 mb-8">
        {{ get500ErrorDescription(error?.statusCode, error?.message) }}
      </p>
      <p class="text-sm text-gray-500 dark:text-gray-400 mb-8">
        {{
          localizeString(
            'If this problem persists, please contact our support team.',
            '如果問題持續存在，請聯絡我們的支援團隊。'
          )
        }}
      </p>
      <div class="flex justify-center gap-4">
        <UButton
          :to="localePath('/')"
          variant="outline"
          icon="i-tabler-arrow-back-up"
        >
          {{ t('cta.goBackHome') }}
        </UButton>
      </div>
    </div>

    <!-- 404 and Other Errors -->
    <div v-else class="text-center px-4">
      <p class="text-9xl font-bold text-primary-500 mb-4 animate-pulse">
        {{ error?.statusCode || '404' }}
      </p>
      <h1 class="text-4xl font-bold text-neutral-900 dark:text-white mb-4">
        {{
          error?.statusCode === 404
            ? localizeString('Page Not Found', '頁面未找到')
            : localizeString('Oops! Something went wrong', '哎呀！發生錯誤')
        }}
      </h1>

      <!-- Detailed description -->
      <p class="text-gray-600 dark:text-gray-400 mb-8">
        {{ getErrorDescription(error?.statusCode, error?.message) }}
      </p>
      <div class="flex justify-center gap-4">
        <UButton
          :to="localePath('/')"
          variant="outline"
          icon="i-tabler-arrow-back-up"
        >
          {{ t('cta.goBackHome') }}
        </UButton>
      </div>
    </div>
  </div>
</template>
