import type { Nav } from '~/types/Nav'
import { API_NAV } from '~/utils/constants'
import { transformNavLinks } from '~/utils/transformers/nav'

export const useNavLinks = () => {
  const { localizeString } = useLocalization()
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const { data: navLinks } = useFetch(API_NAV, {
    key: `navigation-${locale.value}`,
    transform: (links: Nav[]) =>
      transformNavLinks(links, localizeString, localePath),
    watch: [locale],
  })

  return {
    navLinks,
  }
}
