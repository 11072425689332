type PathString = `/${string}`

type Redirects = {
  specific: Record<PathString, PathString>
  base: { from: PathString; to: PathString }[]
}

export default defineNuxtRouteMiddleware((to) => {
  try {
    // Define all redirects
    const redirects: Redirects = {
      // Specific path redirects
      specific: {
        // vaccination
        '/vaccination': '/vaccination/vaccine-list',
        '/hk/vaccination': '/hk/vaccination/vaccine-list',

        // vaccine price
        '/vaccination/vaccine-list': '/vaccination/vaccine-price',
        '/hk/vaccination/vaccine-list': '/hk/vaccination/vaccine-price',

        // Subsidised programme
        '/family-doctor/subsidised-programme':
          '/in-community/subsidised-programme',
        '/hk/family-doctor/subsidised-programme':
          '/hk/in-community/subsidised-programme',

        // Dying in place
        '/outreach/dying-in-place': '/family-doctor/dying-in-place',
        '/hk/outreach/dying-in-place': '/hk/family-doctor/dying-in-place',

        // Health screening
        '/tests/health-screening-package': '/tests/health-screening-packages',
        '/hk/tests/health-screening-package':
          '/hk/tests/health-screening-packages',

        // Vaccine
        '/vaccines': '/vaccination/vaccine-list',
        '/hk/vaccines': '/hk/vaccination/vaccine-list',

        // WordPress legacy
        '/en/health-screening-packages': '/tests/health-screening-packages',
        '/en/subsidised-vaccine': '/vaccination/vaccine-list',
        '/en/medical-consultaion': '/family-doctor/doctor-consultation',
        '/en/outreach-for-groups': '/in-community',
        '/hk/health-screening-packages': '/hk/tests/health-screening-packages',
        '/hk/subsidised-vaccine': '/hk/vaccination/vaccine-list',
        '/hk/medical-consultaion': '/hk/family-doctor/doctor-consultation',
        '/hk/outreach-for-groups': '/hk/in-community',
      },

      // Base path redirects
      base: [
        { from: '/outreach', to: '/in-community' },
        { from: '/hk/outreach', to: '/hk/in-community' },
      ],
    }

    // 1. Check specific path redirects first
    const specificRedirect = redirects.specific[to.path as PathString]
    if (specificRedirect) {
      return navigateTo(specificRedirect)
    }

    // 2. Check base path redirects
    for (const { from, to: target } of redirects.base) {
      if (to.path.startsWith(from)) {
        return navigateTo(to.path.replace(from, target))
      }
    }
  } catch (error) {
    console.error('Redirect middleware error:', error)
  }
})
