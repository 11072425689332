import { API_CLINIC } from '~/utils/constants'

export const useClinic = () => {
  const { localizeString } = useLocalization()
  const { locale } = useI18n()
  const { data: clinics, error } = useFetch(API_CLINIC, {
    key: `clinic-${locale.value}`,
    transform: (clinics) => {
      return clinics.map((clinic) => ({
        ...clinic,
        label: localizeString(clinic.label, clinic.hkLabel),
        address: localizeString(clinic.address, clinic.hkAddress),
        nearestTransport: localizeString(
          clinic.nearestTransport,
          clinic.hkNearestTransport
        ),
        openingHours: localizeString(
          clinic.openingHours,
          clinic.hkOpeningHours
        ),
        fullLogoUrl: localizeString(clinic.fullLogoUrl, clinic.hkFullLogoUrl),
      }))
    },
    watch: [locale],
  })

  return {
    clinics,
    error,
  }
}
