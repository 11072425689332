import { default as aboutIYttGxXltYMeta } from "/workspace/pages/about.vue?macro=true";
import { default as announcementfThuJaNvMHMeta } from "/workspace/pages/announcement.vue?macro=true";
import { default as appointmentVUu9eeeGFrMeta } from "/workspace/pages/appointment.vue?macro=true";
import { default as _91_46_46_46slug_93SpdG9opCAuMeta } from "/workspace/pages/articles/[...slug].vue?macro=true";
import { default as _91slug_93DSdpzprJQGMeta } from "/workspace/pages/articles/category/[slug].vue?macro=true";
import { default as indexWM7XytGBwbMeta } from "/workspace/pages/articles/index.vue?macro=true";
import { default as _91slug_93e6gg8aUYscMeta } from "/workspace/pages/articles/tag/[slug].vue?macro=true";
import { default as articles5w2LR0UCoNMeta } from "/workspace/pages/articles.vue?macro=true";
import { default as _91_46_46_46slug_93NN2JXPYNiPMeta } from "/workspace/pages/family-doctor/[...slug].vue?macro=true";
import { default as indexP0KFAw6DPNMeta } from "/workspace/pages/family-doctor/index.vue?macro=true";
import { default as family_45doctor7nb6RJRXPSMeta } from "/workspace/pages/family-doctor.vue?macro=true";
import { default as _91_46_46_46slug_93qkSNxsdp5LMeta } from "/workspace/pages/in-community/[...slug].vue?macro=true";
import { default as indexd5CRdylAvqMeta } from "/workspace/pages/in-community/index.vue?macro=true";
import { default as in_45communityC6KnlW9xvtMeta } from "/workspace/pages/in-community.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as maintenancegZuggbbijfMeta } from "/workspace/pages/maintenance.vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45userRdwVOUMqRMeta } from "/workspace/pages/terms-of-use.vue?macro=true";
import { default as _91_46_46_46slug_931syPmxYLXrMeta } from "/workspace/pages/tests/[...slug].vue?macro=true";
import { default as indexUMc2bdoRXVMeta } from "/workspace/pages/tests/index.vue?macro=true";
import { default as tests63dLVYI8bhMeta } from "/workspace/pages/tests.vue?macro=true";
import { default as _91_46_46_46slug_93AgRb8MDchkMeta } from "/workspace/pages/travel-health/[...slug].vue?macro=true";
import { default as indexxJz9vpeEPUMeta } from "/workspace/pages/travel-health/index.vue?macro=true";
import { default as travel_45healthGpTLQ4gdWQMeta } from "/workspace/pages/travel-health.vue?macro=true";
import { default as _91_46_46_46slug_93mwrhNI8UytMeta } from "/workspace/pages/vaccination/[...slug].vue?macro=true";
import { default as vaccinationUX4GkTpU0PMeta } from "/workspace/pages/vaccination.vue?macro=true";
export default [
  {
    name: "about___en___default",
    path: "/about",
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___hk",
    path: "/hk/about",
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "announcement___en___default",
    path: "/announcement",
    component: () => import("/workspace/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: "announcement___en",
    path: "/en/announcement",
    component: () => import("/workspace/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: "announcement___hk",
    path: "/hk/announcement",
    component: () => import("/workspace/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: "appointment___en___default",
    path: "/appointment",
    component: () => import("/workspace/pages/appointment.vue").then(m => m.default || m)
  },
  {
    name: "appointment___en",
    path: "/en/appointment",
    component: () => import("/workspace/pages/appointment.vue").then(m => m.default || m)
  },
  {
    name: "appointment___hk",
    path: "/hk/appointment",
    component: () => import("/workspace/pages/appointment.vue").then(m => m.default || m)
  },
  {
    name: articles5w2LR0UCoNMeta?.name,
    path: "/articles",
    component: () => import("/workspace/pages/articles.vue").then(m => m.default || m),
    children: [
  {
    name: "articles-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-category-slug___en___default",
    path: "category/:slug()",
    component: () => import("/workspace/pages/articles/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles___en___default",
    path: "",
    component: () => import("/workspace/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-tag-slug___en___default",
    path: "tag/:slug()",
    component: () => import("/workspace/pages/articles/tag/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: articles5w2LR0UCoNMeta?.name,
    path: "/en/articles",
    component: () => import("/workspace/pages/articles.vue").then(m => m.default || m),
    children: [
  {
    name: "articles-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-category-slug___en",
    path: "category/:slug()",
    component: () => import("/workspace/pages/articles/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles___en",
    path: "",
    component: () => import("/workspace/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-tag-slug___en",
    path: "tag/:slug()",
    component: () => import("/workspace/pages/articles/tag/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: articles5w2LR0UCoNMeta?.name,
    path: "/hk/articles",
    component: () => import("/workspace/pages/articles.vue").then(m => m.default || m),
    children: [
  {
    name: "articles-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-category-slug___hk",
    path: "category/:slug()",
    component: () => import("/workspace/pages/articles/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles___hk",
    path: "",
    component: () => import("/workspace/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-tag-slug___hk",
    path: "tag/:slug()",
    component: () => import("/workspace/pages/articles/tag/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: family_45doctor7nb6RJRXPSMeta?.name,
    path: "/family-doctor",
    component: () => import("/workspace/pages/family-doctor.vue").then(m => m.default || m),
    children: [
  {
    name: "family-doctor-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/family-doctor/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "family-doctor___en___default",
    path: "",
    component: () => import("/workspace/pages/family-doctor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: family_45doctor7nb6RJRXPSMeta?.name,
    path: "/en/family-doctor",
    component: () => import("/workspace/pages/family-doctor.vue").then(m => m.default || m),
    children: [
  {
    name: "family-doctor-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/family-doctor/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "family-doctor___en",
    path: "",
    component: () => import("/workspace/pages/family-doctor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: family_45doctor7nb6RJRXPSMeta?.name,
    path: "/hk/family-doctor",
    component: () => import("/workspace/pages/family-doctor.vue").then(m => m.default || m),
    children: [
  {
    name: "family-doctor-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/family-doctor/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "family-doctor___hk",
    path: "",
    component: () => import("/workspace/pages/family-doctor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: in_45communityC6KnlW9xvtMeta?.name,
    path: "/in-community",
    component: () => import("/workspace/pages/in-community.vue").then(m => m.default || m),
    children: [
  {
    name: "in-community-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/in-community/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "in-community___en___default",
    path: "",
    component: () => import("/workspace/pages/in-community/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: in_45communityC6KnlW9xvtMeta?.name,
    path: "/en/in-community",
    component: () => import("/workspace/pages/in-community.vue").then(m => m.default || m),
    children: [
  {
    name: "in-community-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/in-community/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "in-community___en",
    path: "",
    component: () => import("/workspace/pages/in-community/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: in_45communityC6KnlW9xvtMeta?.name,
    path: "/hk/in-community",
    component: () => import("/workspace/pages/in-community.vue").then(m => m.default || m),
    children: [
  {
    name: "in-community-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/in-community/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "in-community___hk",
    path: "",
    component: () => import("/workspace/pages/in-community/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hk",
    path: "/hk",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en___default",
    path: "/maintenance",
    component: () => import("/workspace/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/en/maintenance",
    component: () => import("/workspace/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___hk",
    path: "/hk/maintenance",
    component: () => import("/workspace/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en___default",
    path: "/privacy-policy",
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___hk",
    path: "/hk/privacy-policy",
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en___default",
    path: "/terms-of-use",
    component: () => import("/workspace/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en",
    path: "/en/terms-of-use",
    component: () => import("/workspace/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___hk",
    path: "/hk/terms-of-use",
    component: () => import("/workspace/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: tests63dLVYI8bhMeta?.name,
    path: "/tests",
    component: () => import("/workspace/pages/tests.vue").then(m => m.default || m),
    children: [
  {
    name: "tests-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/tests/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "tests___en___default",
    path: "",
    component: () => import("/workspace/pages/tests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tests63dLVYI8bhMeta?.name,
    path: "/en/tests",
    component: () => import("/workspace/pages/tests.vue").then(m => m.default || m),
    children: [
  {
    name: "tests-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/tests/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "tests___en",
    path: "",
    component: () => import("/workspace/pages/tests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tests63dLVYI8bhMeta?.name,
    path: "/hk/tests",
    component: () => import("/workspace/pages/tests.vue").then(m => m.default || m),
    children: [
  {
    name: "tests-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/tests/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "tests___hk",
    path: "",
    component: () => import("/workspace/pages/tests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: travel_45healthGpTLQ4gdWQMeta?.name,
    path: "/travel-health",
    component: () => import("/workspace/pages/travel-health.vue").then(m => m.default || m),
    children: [
  {
    name: "travel-health-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/travel-health/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "travel-health___en___default",
    path: "",
    component: () => import("/workspace/pages/travel-health/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: travel_45healthGpTLQ4gdWQMeta?.name,
    path: "/en/travel-health",
    component: () => import("/workspace/pages/travel-health.vue").then(m => m.default || m),
    children: [
  {
    name: "travel-health-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/travel-health/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "travel-health___en",
    path: "",
    component: () => import("/workspace/pages/travel-health/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: travel_45healthGpTLQ4gdWQMeta?.name,
    path: "/hk/travel-health",
    component: () => import("/workspace/pages/travel-health.vue").then(m => m.default || m),
    children: [
  {
    name: "travel-health-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/travel-health/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "travel-health___hk",
    path: "",
    component: () => import("/workspace/pages/travel-health/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "vaccination___en___default",
    path: "/vaccination",
    component: () => import("/workspace/pages/vaccination.vue").then(m => m.default || m),
    children: [
  {
    name: "vaccination-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/vaccination/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "vaccination___en",
    path: "/en/vaccination",
    component: () => import("/workspace/pages/vaccination.vue").then(m => m.default || m),
    children: [
  {
    name: "vaccination-slug___en",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/vaccination/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "vaccination___hk",
    path: "/hk/vaccination",
    component: () => import("/workspace/pages/vaccination.vue").then(m => m.default || m),
    children: [
  {
    name: "vaccination-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/workspace/pages/vaccination/[...slug].vue").then(m => m.default || m)
  }
]
  }
]